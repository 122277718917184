function autoEmbedYoutubeVideoLink() {
	var youTube = $(".youtube-video");
	youTube.each(function () {
		var youTubeUrl = $(this).attr("data-embed"),
			youTubeId,
			regExp =
				/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
			match = youTubeUrl.match(regExp);
		youTubeId =
			match && 11 == match[2].length ? match[2] : "no video found";
		var SRC = `<a data-fancybox href="javascript:;" data-src="https://www.youtube.com/embed/${youTubeId}?autoplay=0&enablejsapi=1&controls=1&loop=0&playsinline=1&color=white&rel=0&cc_load_policy=1&playlist=${youTubeId}" frameborder="0" allowfullscreen></a>`;
		$(this).append(SRC);
	});
	var youTubeIframe = $(".youtube-video-iframe");
	youTubeIframe.each(function (e) {
		var youTubeUrl = $(this).attr("data-embed"),
			youTubeId,
			regExp =
				/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
			match = youTubeUrl.match(regExp);
		youTubeId =
			match && 11 == match[2].length ? match[2] : "no video found";
		var SRC = `<a data-fancybox href="javascript:;" data-src="https://www.youtube.com/embed/${youTubeId}?autoplay=0&enablejsapi=1&controls=1&loop=0&playsinline=1&color=white&rel=0&cc_load_policy=1&playlist=${youTubeId}" frameborder="0" allowfullscreen><iframe src="https://www.youtube.com/embed/${youTubeId}?autoplay=0&enablejsapi=1&controls=1&loop=0&playsinline=1&color=white&rel=0&cc_load_policy=1&playlist=${youTubeId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen</iframe></a>`;
		$(this).append(SRC);
	});
}

$.fn.hasAttr = function (name) {
	return this.attr(name) !== undefined;
};

// ******* Global Variable s*******

// *** Window
var ww = $(window).width();
var body = $("body");
// *** Header *** //
var header = $("header");
var height_header = header.outerHeight();
var menu_nav = header.find("nav");
var burger = header.find("#burger");
var overlay = $("#overlay");
// *** Nav Moble *** //
var nav_mobile = $(".nav-mobile");
var ul_Child = nav_mobile.find(".has-child");
var close_NB = nav_mobile.find("#close-button");
// *** Banner *** //
var pagesBanner = $("#page-banner");
var mainBanner = $("#main-banner");
// *** Footer *** //
$(".search-overlay").removeClass("active");
var footer = $("footer");
// *** Component *** //
// Search
var btn_BackToTop = $("#back-to-top");

const headerFunction = {
	onScroll: () => {
		var lastScrollTop = 0;
		$(window).on("scroll", function () {
			var st = $(this).scrollTop();
			if (st > lastScrollTop && st > height_header) {
				header.addClass("header-scroll");
				$(".search-overlay").addClass("header-scroll");
			} else {
				header.removeClass("header-scroll");
				$(".search-overlay").removeClass("header-scroll");
			}
			lastScrollTop = st;
			// removeSearch();
		});
	},
	navMobileSlide: () => {
		burger.on("click", function () {
			burger.toggleClass("active");
			nav_mobile.toggleClass("active");
			overlay.toggleClass("active");
			body.toggleClass("ovh");
			header.toggleClass("navMobileOpen");
		});
	},
	navMobileChild: () => {
		var li = nav_mobile.find("li");
		var liChild = nav_mobile.find("li.has-child");
		//
		liChild.find("em").on("click", function () {
			if ($(this).closest("li").is(".checkActive")) {
				$(this).closest("li").removeClass("checkActive");
				$(this).closest("li").find(".child").slideUp();
			} else {
				liChild.removeClass("checkActive");
				$(this).closest("li").addClass("checkActive");
				liChild.find(".child").slideUp();
				$(this).closest("li").find(".child").slideDown();
			}
		});
		// onLoad
		li.each(function () {
			if ($(this).is(".active")) {
				$(this).find(".child").show();
			}
		});
	},
	searchActive: () => {
		//
		$(".search-icon").on("click", function () {
			$(".search-overlay").addClass("active");
			header.toggleClass("navMobileOpen");
			setTimeout(() => {
				$(".search-overlay .searchbox input").trigger("focus");
			}, 500);
		});
		$(".search-overlay .searchbox input").on("focus", function () {
			$(this).parent().addClass("active");
		});
		$(".search-overlay .searchbox input").on("focusout", function () {
			$(this).parent().removeClass("active");
		});
		$(document).on("click", function (event) {
			var $trigger = $("header");
			var $trigger_2 = $(".search-overlay");
			if (
				$trigger !== event.target &&
				!$trigger.has(event.target).length &&
				$trigger_2 !== event.target &&
				!$trigger_2.has(event.target).length
			) {
				$(".search-overlay").removeClass("active");
				header.removeClass("navMobileOpen");
			}
		});
		$(".search-overlay .searchinput").keyup(function () {
			$(".search-overlay .wrapper").addClass("fixed-title");
			if ($(this).val() == "") {
				$(".search-overlay .wrapper").removeClass("fixed-title");
			}
		});
		// Press ESC to close Search
		setTimeout(() => {
			$(window).on("keyup", function (event) {
				var e = event.keyCode;
				if (e == 27) {
					$(".search-overlay").removeClass("active");
				}
			});
		}, 500);

		// Trigger click with 2 button
		$(".search-overlay .searchbox input[type='text']").keypress(function (
			n
		) {
			if (n.keyCode == 13)
				$(".searchbox button").trigger("click"), console.log("click");
		});
	},
	escBtn: () => {
		$(window).on("keyup", function (event) {
			var e = event.keyCode;
			if (e == 27) {
				nav_mobile.removeClass("active");
				header.removeClass("blur-content");
				burger.removeClass("active");
				overlay.removeClass("active");
				body.removeClass("ovh");
				header.removeClass("navMobileOpen");
			}
		});
	},
	init: () => {
		headerFunction.onScroll();
		headerFunction.navMobileSlide();
		headerFunction.navMobileChild();
		headerFunction.searchActive();
		headerFunction.escBtn();
	},
};

// *** Video Variable
function swiperInit() {
	var main_banner = new Swiper("#main-banner .swiper", {
		spaceBetween: 0,
		autoplay: {
			delay: 4500,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		speed: 500,
		observer: true,
		observeParents: true,
		pagination: {
			el: "#main-banner .swiper-pagination",
		},
		navigation: {
			prevEl: "#main-banner .swiper-button-prev",
			nextEl: "#main-banner .swiper-button-next",
		},
	});
	var index_2 = new Swiper(".index-2-slide .swiper", {
		spaceBetween: 0,
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		speed: 500,
		observer: true,
		observeParents: true,
		pagination: {
			el: ".index-2-slide .swiper-pagination",
			clickable: true,
		},
		navigation: {
			prevEl: ".index-2-slide .swiper-button-prev",
			nextEl: ".index-2-slide .swiper-button-next",
		},
	});
	var index_6 = new Swiper(".index-6 .swiper", {
		speed: 500,
		navigation: {
			prevEl: ".index-6 .swiper-button-prev",
			nextEl: ".index-6 .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
		},
	});

	// **** Product **** //
	var product_detail_thumb = new Swiper(".product-detail .thumb .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		allowTouchMove: false,
		breakpoints: {
			1280: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			1024: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			576: {
				spaceBetween: 10,
				slidesPerView: 3,
			},
			320: {
				slidesPerView: 2,
				spaceBetween: 5,
			},
		},
	});
	var product_detail_main = new Swiper(".product-detail .main .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		spaceBetween: 0,
		thumbs: {
			swiper: product_detail_thumb,
		},
		navigation: {
			prevEl: ".product-detail .swiper-button-prev",
			nextEl: ".product-detail .swiper-button-next",
		},
		on: {
			slideChange: function () {
				//
				autoSlideNextOnThumbSlide(this, ".product-detail", ".thumb");
			},
		},
	});
	var product_other = new Swiper(".product-other-slide .swiper", {
		speed: 500,
		navigation: {
			prevEl: ".product-other-slide .swiper-button-prev",
			nextEl: ".product-other-slide .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
		},
	});

	// **** Factory **** //
	var factory_detail = new Swiper(".factory-detail-1 .swiper", {
		spaceBetween: 0,
		autoplay: {
			delay: 4500,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ["-20%", 0, -1],
			},
			next: {
				translate: ["100%", 0, 0],
			},
		},
		speed: 500,
		observer: true,
		observeParents: true,
		pagination: {
			el: ".factory-detail-1 .swiper-pagination",
			clickable: true,
		},
		navigation: {
			prevEl: ".factory-detail-1 .swiper-button-prev",
			nextEl: ".factory-detail-1 .swiper-button-next",
		},
	});
	var factory_other = new Swiper(".factory-other-slide .swiper", {
		speed: 500,
		navigation: {
			prevEl: ".factory-other-slide .swiper-button-prev",
			nextEl: ".factory-other-slide .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
		},
	});

	// **** Quan hệ cổ đông **** //
	var codong_detail_nav = new Swiper(".codong-detail .nav .swiper", {
		speed: 500,
		observer: true,
		observeParents: true,
		breakpoints: {
			1280: {
				spaceBetween: 80,
				slidesPerView: 8,
				allowTouchMove: false,
			},
			1024: {
				spaceBetween: 70,
				slidesPerView: 7,
			},
			768: {
				spaceBetween: 60,
				slidesPerView: 6,
			},
			576: {
				spaceBetween: 45,
				slidesPerView: 6,
			},
			320: {
				spaceBetween: 20,
				slidesPerView: 4,
			},
		},
		on: {
			init: function () {
				if ($(".codong-detail .swiper-slide.activeIndex").length == 1) {
					var activeSwiperIndex = Number(
						$(".codong-detail .nav .swiper-slide.activeIndex").attr(
							"data-swiper-index"
						)
					);
					this.slideTo(activeSwiperIndex, 0, false);
				}
			},
		},
		navigation: {
			prevEl: ".codong-detail .nav .swiper-button-prev",
			nextEl: ".codong-detail .nav .swiper-button-next",
		},
	});

	// **** Truyền thông **** //
	var truyenthong_other = new Swiper(".truyenthong-detail .swiper", {
		speed: 500,
		navigation: {
			prevEl: ".truyenthong-detail .swiper-button-prev",
			nextEl: ".truyenthong-detail .swiper-button-next",
		},
		breakpoints: {
			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			576: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
			320: {
				slidesPerView: 1,
				spaceBetween: 15,
			},
		},
	});
}
function autoSlideNextOnThumbSlide(initSwiper, element, thumb) {
	let activeIndex = initSwiper.activeIndex + 1;
	let activeSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex})`
	);
	let nextSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex + 1})`
	);
	let prevSlide = document.querySelector(
		`${element} ${thumb} .swiper-slide:nth-child(${activeIndex - 1})`
	);

	if (nextSlide && !nextSlide.classList.contains("swiper-slide-visible")) {
		initSwiper.thumbs.swiper.slideNext();
	} else if (
		prevSlide &&
		!prevSlide.classList.contains("swiper-slide-visible")
	) {
		initSwiper.thumbs.swiper.slidePrev();
	}
}
function expandItem() {
	// Filter Load
	var hasLoadBtn = $("[has-expand-btn]");
	hasLoadBtn.each(function () {
		var $this = $(this);
		var expandBtn = $(this).find(".expand-btn");
		var list = $(this).find(".expand-item").length;
		var count;
		var countMobile = $(this).attr("item-count-mobile");
		countMobile != undefined && countMobile != 0 && ww < 576
			? (count = Number($(this).attr("item-count-mobile")))
			: (count = Number($(this).attr("item-count")));
		var expand = Number($(this).attr("item-expand"));
		var isFlex = false;
		$(this).hasAttr("expand-flex") ? (isFlex = true) : (isFlex = false);
		//=// Init

		function init(initValue, thisFunction) {
			isFlex
				? thisFunction
						.find(".expand-item")
						.slice(0, initValue)
						.slideDown({
							complete: function () {
								$(this).css("display", "flex");
								$(this).css("flex-direction", "column");
								setTimeout(() => {
									$(this).addClass("done-animated");
								}, 300);
							},
						})
				: thisFunction
						.find(".expand-item")
						.slice(0, initValue)
						.slideDown({
							complete: function () {
								setTimeout(() => {
									$(this).addClass("done-animated");
								}, 300);
							},
						});
			if (list == 0 || list <= initValue) {
				expandBtn.hide();
			}
		}
		init(count, $this);

		// Click
		function expandBtnInit(initCount, thisFunction) {
			count = initCount + expand <= list ? initCount + expand : list;
			isFlex
				? thisFunction
						.closest(hasLoadBtn)
						.find(".expand-item")
						.slice(0, count)
						.slideDown({
							complete: function () {
								$(this).css("display", "flex");
								$(this).css("flex-direction", "column");
								setTimeout(() => {
									$(this).addClass("done-animated");
								}, 300);
							},
						})
				: thisFunction
						.closest(hasLoadBtn)
						.find(".expand-item")
						.slice(0, count)
						.slideDown({
							complete: function () {
								setTimeout(() => {
									$(this).addClass("done-animated");
								}, 300);
							},
						});
			// console.log(count);
			if (count == list) {
				thisFunction.slideUp();
			}
		}
		expandBtn.on("click", function (e) {
			e.preventDefault();
			expandBtnInit(count, $(this));
		});
	});
}

const cmsFunc = {
	toggleAdminCms: () => {
		function Get_Cookie(check_name) {
			var a_all_cookies = document.cookie.split(";");
			var a_temp_cookie = "";
			var cookie_name = "";
			var cookie_value = "";
			var b_cookie_found = false;
			var i = "";
			for (i = 0; i < a_all_cookies.length; i++) {
				a_temp_cookie = a_all_cookies[i].split("=");
				cookie_name = a_temp_cookie[0].replace(/^\s+|\s+$/g, "");
				if (cookie_name == check_name) {
					b_cookie_found = true;
					if (a_temp_cookie.length > 1) {
						cookie_value = unescape(
							a_temp_cookie[1].replace(/^\s+|\s+$/g, "")
						);
					}
					return cookie_value;
					break;
				}
				a_temp_cookie = null;
				cookie_name = "";
			}
			if (!b_cookie_found) {
				return null;
			}
		}
		function Set_Cookie(name, value, expires, path, domain, secure) {
			var today = new Date();
			today.setTime(today.getTime());
			if (expires) {
				expires = expires * 1000 * 60 * 60 * 24;
			}
			var expires_date = new Date(today.getTime() + expires);
			document.cookie =
				name +
				"=" +
				escape(value) +
				(expires ? ";expires=" + expires_date.toGMTString() : "") +
				(path ? ";path=" + path : "") +
				(domain ? ";domain=" + domain : "") +
				(secure ? ";secure" : "");
		}
		var cmsAdminPanel = document.querySelector(".cmsadminpanel");
		var cmsAdminPanelBtn = document.querySelector(
			".cmsadminpanel .btn-toggle"
		);
		cmsAdminPanelBtn.addEventListener("click", function (e) {
			e.preventDefault();
			if (
				e.target.closest(".cmsadminpanel").classList.contains("active")
			) {
				ShowToolbar();
				Set_Cookie("toolbarOpenState", "open");
			} else {
				HideToolbar();
				Set_Cookie("toolbarOpenState", "closed");
			}
		});
		var openState = Get_Cookie("toolbarOpenState");
		if (openState != null) {
			if (openState == "closed") {
				HideToolbar();
			}
			if (openState == "open") {
				ShowToolbar();
			}
		}

		function HideToolbar() {
			cmsAdminPanel.classList.add("active");
		}

		function ShowToolbar() {
			cmsAdminPanel.classList.remove("active");
		}
	},
	pagination: () => {
		$(".modulepager .pagination")
			.find("a.NextPage, a.LastPage, a.BackPage, a.FirstPage")
			.parent()
			.hide();
	},
	mappingPagination: () => {
		$(".pages.newspager").each(function () {
			$(this).prev(".section").append(this);
		});
		if ($(".homepage").length == 1) {
			$(".pages.newspager").remove();
		}
	},

	checkEditMode: () => {
		if ($(".cmsadminpanel").length == 1) {
			if (
				$(".cmsadminpanel .ddviewmode option[value='Edit']").is(
					":selected"
				)
			) {
				$("body").addClass("editmode");
			}
		}
	},
	addClassCustomPage: () => {
		if ($(".news-article").length == 1)
			$("body").addClass("news-detail__page");
	},
	init: () => {
		// cmsFunc.toggleAdminCms();
		cmsFunc.pagination();
		cmsFunc.mappingPagination();
		cmsFunc.checkEditMode();
		cmsFunc.addClassCustomPage();
	},
};

function setHeightEqualToWidth() {
	var widthItem = $("[widthEqualHeight]");
	widthItem.each(function () {
		$(this).css({
			height: $(this).outerWidth(),
		});
	});
}

const globalJS = {
	lozadFunction: () => {
		var observer = lozad(".lozad", {
			threshold: 0.1,
			enableAutoReload: true,
			load: function (el) {
				el.src = el.getAttribute("data-src");
				//
				setHeightEqualToWidth();
			},
		});
		var backgroundObserver = lozad(".lozad-background", {
			threshold: 0.1,
		});

		observer.observe();
		backgroundObserver.observe();
	},
	autoFillNoImage: () => {
		setTimeout(() => {
			$("img").each(function () {
				if ($(this).hasAttr("data-src")) {
					if ($(this).attr("data-src") === "") {
						$(this).attr("data-src", "/no-image.png");
						$(this).attr("src", "/no-image.png");
						$(this).addClass("noImage");
					}
				} else if ($(this).hasAttr("src")) {
					if ($(this).attr("src") === "") {
						$(this).attr("src", "/no-image.png");
						$(this).addClass("noImage");
					}
				}
			});
		}, 150);
	},
	autoAppend: () => {
		var appendId = $("[id*='autoAppend-']");
		appendId.each(function (e) {
			var id = $(this).attr("id").slice(11);
			// console.log(id);
			$(this).appendTo("[id*='autoAppendHere-" + id + "']");
			var curHeight = $(this).get(0).scrollHeight;
		});
	},
	autoClone: () => {
		var cloneId = $("[id*='autoClone-']");
		cloneId.each(function (e) {
			var id = $(this).attr("id").slice(10);
			// console.log(id);
			$(this)
				.clone()
				.appendTo("[id*='autoCloneHere-" + id + "']");
		});
	},
	setBackground: () => {
		var position = ["", "top", "left", "right", "bottom"];
		jQuery.each(position, function (index, item) {
			//
			//
			$(`[setBackground${item}]`).each(function () {
				var background = $(this).attr("setBackground" + item);
				$(this).css({
					"background-size": "cover",
					"background-position": `${item} center`,
					"background-image": `url(${background})`,
				});
			});
		});
	},
	scrollToTop: () => {
		$(window).on("scroll", function () {
			if (
				$(this).scrollTop() >= mainBanner.outerHeight() ||
				$(this).scrollTop() >= pagesBanner.outerHeight()
			) {
				$("#scroll-to-top").addClass("active");
			} else {
				$("#scroll-to-top").removeClass("active");
			}
		});
		$("#scroll-to-top").on("click", function () {
			$("html,body").animate({
				scrollTop: 0,
			});
		});
	},
	init: () => {
		globalJS.lozadFunction();
		globalJS.autoFillNoImage();
		globalJS.autoAppend();
		globalJS.autoClone();
		globalJS.setBackground();
		globalJS.scrollToTop();
		// globalJS.mainPadding();
	},
};

function counterUpNumber() {
	$(".counter").counterUp({
		delay: 10,
		time: 1000,
	});
}

function activeCommonNav() {
	$(".common-nav li").each(function () {
		if ($(this).hasClass("active")) {
			$(this).closest("ul").scrollLeft($(this).position().left);
		}
	});
}

const handleFormWebSite = {
	pageContact: () => {
		$(".Module-1358 .frm-captcha").appendTo(
			".Module-1358 [id*='_pnlQuestions']"
		);
	},
	init: () => {
		handleFormWebSite.pageContact();
	},
};

function accordion() {
	var item = $(".accordion-item");
	var title = $(".accordion-title");
	var content = $(".accordion-content");
	var active = $("accordion-item.active");
	title.on("click", function () {
		if ($(this).closest(item).is(".active")) {
			$(this).closest(item).removeClass("active");
			$(this).closest(item).find(content).slideUp();
		} else {
			title.closest(item).removeClass("active");
			title.closest(item).find(content).slideUp();
			$(this).closest(item).addClass("active");
			$(this).closest(item).find(content).slideDown();
		}
	});
	// Onload
	item.eq(0).addClass("active");
	item.eq(0).find(content).slideDown();
}

$(document).ready(function () {
	// ---***--- Global JS ---***---
	globalJS.init();
	cmsFunc.init();

	// ---***--- Header ---***---
	headerFunction.init();

	// ---***--- Body ---***---
	counterUpNumber();
	expandItem();
	activeCommonNav();
	accordion();

	// ---***--- Youtube ---***---
	autoEmbedYoutubeVideoLink();

	// ---***--- Form ---***---

	// ---***--- Fancybox ---***---
	Fancybox.bind("[data-fancybox]", {});
	Fancybox.bind("a.has-form[data-fancybox]", {
		parentEl: document.forms[0],
	});

	// ---***--- Swiper ---***---
	swiperInit();

	if ($(".product-detail").length == 1) {
		setTimeout(() => {
			swiperInit();
		}, 1000);
	}
});

$(window).resize(function () {
	setHeightEqualToWidth();
});
$(window).trigger("resize");

// Load FireAnts
const handleFireAntId = document.getElementById("fan-quote-327");
if (handleFireAntId != null) {
	// Append
	setTimeout(() => {
		new FireAnt.QuoteWidget({
			container_id: "fan-quote-327",
			symbols: "HTG",
			locale: "vi",
			price_line_color: "#71BDDF",
			grid_color: "#999999",
			label_color: "#999999",
			width: "100%",
			height: "700px",
		});
	}, 300);
}
